import * as React from 'react'

import Layout from '../components/layout'
import EventHire from '../components/EventHire/eventHire'
import SEO from '../components/seo'

const EventHirePage = () => (
  <Layout>
    <EventHire />
  </Layout>
)

export default EventHirePage

export const Head = () => (
  <SEO
    page='Event Hire'
    description="If you're planning a public event, why not consider inviting us to be a part of it? As a business, we are always looking for opportunities to connect with our community and showcase our delicious treats."
  />
)
