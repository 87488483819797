import React from 'react'
import TOPEventHire from '../../assets/images/TOP_event_hire.png'
import img6 from '../../assets/images/img6.png'
import ContactForm from '../ContactForm'

const EventHire = () => {
  return (
    <>
      <div className='fluid'>
        <div className='position-relative overflow-hidden'>
          <img src={TOPEventHire} alt='Shop' className='img-full' />
          <h3 className='text-white font-secondary position-absolute bottom-0 ps-3 d-block d-sm-none'>
            SHOP
          </h3>
        </div>
      </div>
      <div className='container px-5'>
        <div className='text-center pb-3'>
          <h1 className='text-black pt-2 font-secondary my-4'>Past events</h1>
        </div>
        <article>
          <h4 className='font-secondary'>
            Community Day at 2 Coin Street - 108 Stamford St, SE1 9NH
          </h4>
          <p>
            On <strong>July 8, 2023</strong>, we had the pleasure of being part
            of the Community Day at Coin Street Neighbourhood Centre, located at{' '}
            <strong>108 Stamford St, SE1 9NH</strong>. With <strong>100</strong>{' '}
            attendees and a tantalizing menu, it was a day to remember.
          </p>
          <p>
            Our offerings included aromatic coffee, delectable cakes, and trendy
            bubble tea. The flavors were a hit among the attendees, creating a
            warm and inviting atmosphere.
          </p>
          <p>
            Though the event has passed, the memories of our delightful drinks
            and treats linger on. It was a day that showcased our commitment to
            bringing people together over great flavors.
          </p>
        </article>
        <article>
          <h4 className='font-secondary'>
            Private Garden Open Day - Temple, London, EC4Y 7HB
          </h4>
          <p>
            On <strong>May 31, 2023</strong>, we had the pleasure of
            participating in the Private Garden Open Day at{' '}
            <strong>Temple, London, EC4Y 7HB</strong>. With a whopping{' '}
            <strong>1000</strong> attendees, this event was a massive success.
          </p>
          <p>
            Our menu featured the finest coffee, mouthwatering cakes, and trendy
            bubble tea. These delectable options attracted the large crowd,
            creating a vibrant and enjoyable atmosphere.
          </p>
          <p>
            Although the event has concluded, the memories of our delicious
            offerings linger. It was a day that showcased our dedication to
            providing exceptional flavors and service to a discerning audience.
          </p>
        </article>
        <article>
          <h4 className='font-secondary'>
            Community Fun day at 1 - 18 Blackfriars Road - 61 Stamford St, SE1
            9NA
          </h4>
          <p>
            On <strong>May 20, 2023</strong>, our coffee business was at the
            heart of the action during the Community Fun day at 1-18 Blackfriars
            Road. With <strong>200</strong> attendees and an array of delicious
            offerings, it was a memorable event.
          </p>
          <p>
            We served up aromatic coffee, mouthwatering cakes, and trendy bubble
            tea, making it a flavorsome affair. People gathered to enjoy our
            brews and treats, creating a warm and welcoming atmosphere.
          </p>
          <p>
            Though the event is now a cherished memory, our coffee business made
            its mark, bringing people together over great coffee and delightful
            treats. It&apos;s a moment we&apos;ll always remember with pride.
          </p>
        </article>
      </div>

      <div className='container text-center px-5 mb-5'>
        <h1 className='text-black pt-3 font-secondary my-4'>
          Mobile barista services
        </h1>
        <div className=' container text-center mx-auto'>
          <p>
            Our trained baristas are at your service, so you can order any
            beverage you desire. Our coffee cart is stylish and cool-looking,
            complete with a well-trained barista to cater to your every need. We
            are self-sufficient and only require a spot to serve up our
            top-quality coffee.
          </p>
          <p>
            Our services are perfect for a wide variety of public events,
            including fetes, carnivals, sporting fixtures, music festivals,
            country shows, car, truck and air shows, boat festivals, charity
            events, and much more. We have provided our services at numerous
            events and are proud to be a part of the local community.
          </p>
          <p>
            At the Panda Cup, we take pride in serving up great coffee. Our
            baristas are experts at their craft and use only the finest
            ingredients to create our delicious beverages. Whether you prefer a
            classic latte or a unique creation, we have something for everyone.
          </p>
          <p>
            Thank you for considering the Panda Cup for your next event. We look
            forward to serving you soon!
          </p>

          <img src={img6} alt='Event-hire' className='img-fluid my-5' />
          <div className='mb-5'>
            <p>
              Are you planning a public event? Why not invite us to join in the
              festivities? We are happy to pay an attendance fee that is
              proportional to the footfall at your event. Our coffee vans are a
              fantastic addition to any outdoor event, including festivals,
              markets, and community gatherings.
            </p>
            <p>
              Our services are not limited to public events; we can also add a
              unique touch to private events such as weddings, garden parties,
              and corporate events. We can provide a quote based on your
              requirements and the number of guests in attendance.
            </p>
            <p>
              At the Panda Cup, we take pride in delivering high-quality coffee
              that is both delicious and visually appealing. Our baristas are
              trained to create a range of beverages, from classic
              espresso-based drinks to unique creations that are sure to
              impress.
            </p>
            <p>
              So why not consider adding the Panda Cup to your next event? We
              are committed to providing top-notch service and excellent coffee
              that will make your event stand out. Contact us today to discuss
              how we can help make your event a success!
            </p>
          </div>
        </div>
      </div>
      <div className='background-image--contactFormBg '>
        <div className='container px-5'>
          <h1 className='text-black p-5 text-center font-secondary'>Hire Us</h1>
          <ContactForm page='Event Hire' />
        </div>
      </div>
    </>
  )
}

export default EventHire
